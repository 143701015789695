<template>
  <div uk-height-viewport class="uk-flex uk-flex-middle">
    <div class="uk-width-3-3@m uk-width-1-3@s m-auto rounded">
      <div class="uk-child-width-2-2@m uk-grid-collapse" uk-grid>
        <div class="uk-card-default p-4 rounded">
          <div class="mb-4">
            <h3 class="mb-3"> {{ $t('forgot_password.header') }}</h3>
            <ul style="list-style-type: circle;padding-left:15px !important;">
              <li style="font-size:.875rem;">{{ $t('forgot_password.first_error') }}</li>
              <li style="font-size:.875rem;">{{ $t('forgot_password.second_error') }}</li>
              <li style="font-size:.875rem;">{{ $t('forgot_password.third_error') }}</li>
            </ul>
          </div>

          <form @submit.prevent="onSubmit()">
            <div class="uk-form-group">
              <!-- <label class="uk-form-label"> {{ $t('forgot_password.email') }}</label> -->
              <label class="uk-form-label"> E-posta veya telefon</label>


              <div class="uk-position-relative w-100">
                <!-- <span class="uk-form-icon">
                  <i class="icon-feather-mail"></i>
                </span> -->
                <input class="uk-input" required v-model="username">

              </div>

              <div class="uk-alert-danger" uk-alert v-if="errors">
                <a class="uk-alert-close" uk-close></a>
                <p style="color:red;"><i class="uil-exclamation-triangle"></i> {{ errors }}</p>
              </div>

              <div class="uk-alert-success" uk-alert v-if="message">
                <a class="uk-alert-close" uk-close></a>
                <p style="color:green;"><i class="uil-exclamation-triangle"></i> {{ message }}
                </p>
              </div>
            </div>
            <div class="mt-4 uk-flex-middle uk-grid-small" uk-grid>
              <div class="uk-width-expand@s">
                <button type="button" class="btn mr-2" @click="$router.push({ name: 'login' })">
                  <i class="uil-arrow-left"></i> {{ $t('general.back') }}
                </button>
              </div>
              <div class="uk-width-auto@s">
                <button type="submit" :disabled="submitButtonDisabled" class="btn btn-default">
                  {{ $t('forgot_password.send') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";
import { SEND_EMAIL, SET_ERROR } from "@/core/services/store/auth.module";

export default {
  name: "ForgotPassword",
  data() {
    return {
      username: null,
      phone: null,
      message: null,
      submitButtonDisabled: false,
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
  },
  methods: {
    onSubmit() {
      let username = this.username
      this.message = null
      this.submitButtonDisabled = true;
      let alias = process.env.VUE_APP_DEFAULT_SUBDOMAIN;
      if (['production', 'staging'].includes(process.env.NODE_ENV)) {
        alias = window.location.hostname.split(".")[0]
      }

      this.$store.dispatch(SEND_EMAIL, { username, alias })
        .then((response) => {
          this.message = this.$t('messages.reset_link_send')
          this.submitButtonDisabled = false;
        }).catch((e) => {
          this.submitButtonDisabled = false;
        })
        setTimeout(() => {
          this.submitButtonDisabled = false;
        }, 1000);
    }
  },
  mounted() {
    this.$store.commit(SET_ERROR, '')
  }
}
</script>

<style scoped></style>